 <template>
  <!-- 样式组件 -->
  <div :class="'liefeng-menu-view'" v-if="!isParamComp">
    <img src="/images/grad1.png" />
  </div>
  <!-- 参数设置组件 -->
  <div class="liefeng-banner-params" v-else>
    <Form :label-width="100" ref="bannerForm">
      <FormItem label="菜单模式">
        <RadioGroup v-model="data.menuType" @on-change="handleData">
          <Radio label="page">分页切换</Radio>
          <Radio label="pulldown">下拉显示更多</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="行数" v-show="data.menuType == 'page'">
        <Input
          type="Number"
          v-model.number="data.rows"
          placeholder="默认：3"
        ></Input>
      </FormItem>
      <FormItem label="列数">
        <Input
          type="Number"
          v-model.number="data.columnNum"
          placeholder="默认：4"
        ></Input>
      </FormItem>
      <FormItem label="布局模式">
        <RadioGroup v-model="data.mode" @on-change="handleData">
          <Radio label="square">上图标下标题</Radio>
          <Radio label="rect">左图标右标题</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="宫格边框">
        <RadioGroup v-model="data.hasBorder">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="图标阴影">
        <RadioGroup v-model="data.shadow">
          <Radio :label="true">是</Radio>
          <Radio :label="false">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="数据来源">
        <RadioGroup v-model="data.dataFrom">
          <Radio label="api">通过API或JSON数据获取</Radio>
          <Radio label="menu">直接获取当前栏目的子栏目列表</Radio>
        </RadioGroup>
      </FormItem>

      <FormItem label="API地址" v-if="data.dataFrom != 'menu'">
        <Input
          v-model.trim="data.api"
          placeholder="api优先于固定JSON数据"
          type="textarea"
         :autosize="true"
        ></Input>
        <div style="color:blue">注：变量赋值参数的伪参数写法：abc=${def}</div>
      </FormItem>
      <FormItem label="响应参数互换" v-if="data.dataFrom != 'menu' && data.api">
        <Input
          v-model.trim="data.convertParams"
          type="textarea"
          rows="10"
          placeholder='响应参数互换是指：接口输出的参数与组件不一致，需要互换后才能在页面上正常显示。例：
{
  "新参数名称": "原参数名称，若参数不存在，则视为常量",
  "imageUrl":"image"
}
              '
        ></Input>
        <Button
          style="margin: 5px"
          type="success"
          @click="verifyJson('convertParams')"
          >格式化 / 验证JSON合法性</Button
        >
        <APIreadme/>
      </FormItem>
      <FormItem label="JSON数据" v-if="data.dataFrom != 'menu' && !data.api">
        <Input
          v-model.trim="data.data"
          type="textarea"
          rows="15"
          placeholder='例：[
                {
                  "image": "图片地址",
                  "value": "名称",
                  "url": "跳转地址（可选）原生功能在地址前加：original://",
                  "code":"ID"
                }
              ]'
        ></Input>
        <Button style="margin: 5px" type="success" @click="verifyJson('data')"
          >格式化 / 验证JSON合法性</Button
        >
        <Button style="margin: 5px" type="info" @click="importDfData"
          >导入例子</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
/**
 * import Menu from "./components/Menu";
 * 菜单组件
 */
import APIreadme from "./APIreadme";
export default {
  components: {APIreadme},
  props: {
    isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
    data: {
      //组件数据，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
    target: {
      //组件对象，每个组件需要具备
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      initData: {
        //初始化用的数据，每个组件需要具备
        type: "Menu",
        dataFrom: "api",
        api: "",
        data: null,
        menuType: "page",
        rows: 3,
        columnNum: 4,
        mode: "square",
        hasBorder: false,
        shadow: false,
      },
      dfData: JSON.stringify(
        [
          {
            image: "./apps/suiyue/images/remote/banner_03.png",
            url: "",
            value: "领取中心",
            code: "1",
          },
          {
            image: "./apps/suiyue/images/remote/banner_04.png",
            url: "",
            value: "领会员",
            code: "2",
          },
        ],
        null,
        "\t"
      ),
    };
  },
  created() {
    this.handleData();
  },
  methods: {
    importDfData() {
      if (this.data.data) {
        this.$Modal.confirm({
          title: "提示",
          content: "编辑框已存在数据，是否覆盖？",
          onOk: (res) => {
            this.data.data = this.dfData;
          },
        });
      } else {
        this.data.data = this.dfData;
      }
    },
    handleData() {
      if (this.data.menuType == "page") {
        if (this.data.mode == "square")
          this.$(this.target).find("img").attr("src", "/images/grad1.png");
        else this.$(this.target).find("img").attr("src", "/images/grad3.png");
      } else {
        if (this.data.mode == "square")
          this.$(this.target).find("img").attr("src", "/images/grad2.png");
        else this.$(this.target).find("img").attr("src", "/images/grad4.png");
      }
    },
    verifyJson(target) {
      //验证JSON,每个组件需要具备
      try {
        if (!this.data[target]) {
          this.$Modal.warning({ title: "验证结果", content: "没有JSON数据。" });
        } else {
          var json = JSON.parse(this.data[target]);
          this.$Modal.success({ title: "验证结果", content: "JSON数据正确。" });
          this.data[target] = JSON.stringify(json, null, "\t");
        }
      } catch (e) {
        this.$Modal.error({
          title: "验证结果",
          content: "JSON数据有误，请检查。",
        });
      }
    },
  },
};
</script>
<style lang="less">
.liefeng-menu-view {
  width: 100%;
  margin: 5px 0;
  overflow: hidden;
  background-color: #eee;
  img {
    width: 100%;
  }
}
</style>